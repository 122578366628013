import React from 'react';
import { ChallengeQuestionType } from '../../contract_enums';
import InvoiceQuestionChallenge from './InvoiceQuestionChallenge';
import PassKeyQuestionChallenge from './PassKeyQuestionChallenge';

const QuestionChallenge = ({ id, ...props }) => {
  switch (id) {
    case ChallengeQuestionType.RecentInvoice:
      return <InvoiceQuestionChallenge id={id} {...props} />;
    case ChallengeQuestionType.PassKey:
      return <PassKeyQuestionChallenge id={id} {...props} />;
    default:
      return <></>;
  }
};

export default QuestionChallenge;
