import { Portal, portal } from './general';

const MainSiteUrlLinks = {
  [Portal.Gascaribe]: 'https://www.gascaribe.com',
  [Portal.Gasguajira]: 'https://www.gasesdelaguajira.com',
  [Portal.Efigas]: 'https://www.efigas.com.co',
  [Portal.TripleA]: 'https://www.aaa.com.co'
};

const PrivacyPoliciesLinks = {
  [Portal.Gascaribe]:
    'https://gascaribe.com/politica-de-proteccion-de-datos/?et_fb=1&PageSpeed=off',
  [Portal.Gasguajira]:
    'https://www.gasesdelaguajira.com/index.php/work/politica-de-tratamiento-de-la-informacion',
  [Portal.Efigas]:
    'https://www.efigas.com.co/Portals/0/Pdf/Documentos/POLITICA-DE-TRATAMIENTO-DE-LA-INFORMACION-Y-DATOS-PERSONALES-EFIGAS.pdf',
  [Portal.TripleA]: 'https://www.aaa.com.co/ley-de-proteccion-de-datos'
};

const BrillaFinancingLinks = {
  [Portal.Gascaribe]: 'https://www.brillagascaribe.com/donde-financiar',
  [Portal.Gasguajira]: 'https://www.brillagasguajira.com/que-puedo-financiar',
  [Portal.Efigas]: 'https://www.brilladeefigas.com/que-puedo-financiar'
};

const BrillaQuotaLinks = {
  [Portal.Gascaribe]: 'https://www.brillagascaribe.com/tu-cupo',
  [Portal.Gasguajira]: 'https://www.brillagasguajira.com/tu-cupo',
  [Portal.Efigas]: 'https://www.brilladeefigas.com/tu-cupo'
};

const CookiesPoliciesLinks = {
  [Portal.Gascaribe]: 'https://www.brillagascaribe.com/politica-de-cookies',
  [Portal.Gasguajira]: 'https://www.brillagasguajira.com/politica-de-cookies',
  [Portal.Efigas]:
    'https://www.brilladeefigas.com/imagenes/aviso-de-privacidad-y-politica-cookies-brilla-efigas.pdf'
};

const HabeasDataEmails = {
  [Portal.Gascaribe]: 'habeasdata@gascaribe.com',
  [Portal.Gasguajira]: 'habeasdata@gasguajira.com',
  [Portal.Efigas]: 'habeasdata@efigas.com.co',
  [Portal.TripleA]: 'habeasdata@aaa.com.co'
};

const WhatsAppHelpLinks = {
  [Portal.Gascaribe]: 'https://api.whatsapp.com/send?phone=576053227000',
  [Portal.Efigas]: 'https://chat.efigas.com.co/webchatefigas'
};

const ChatbotLinks = {
  [Portal.Efigas]:
    'https://webbot.me/898525ed49a9232b5e539620bc8f5837e657aa4ea818bba07ffd10cd1bd8f525'
};

const PuntualizateLinks = {
  [Portal.Gascaribe]: 'https://puntualizate.gascaribe.com/armalo'
};

const SimulatorBrillaUrlLinks = {
  [Portal.Efigas]: 'https://www.brilladeefigas.com/tu-cupo#simula-tu-credito'
};

const GiveAwayPrivNotifLinks = {
  [Portal.Gascaribe]: 'http://portal.gascaribe.com/aviso-privacidad-sorteos.pdf'
};

const GanaLocoCampaignLinks = {
  [Portal.Gascaribe]: 'https://portal.gascaribe.com/T&C-gana-loco.pdf'
};

const PagaGanaCampaignTermsLinks = {
  [Portal.Efigas]: 'https://clientes.efigas.com.co/home/politicapse'
};

const InvoiceUrlLinks = {
  [Portal.Gasguajira]:
    'http://190.14.228.238:2377/consulta_factura/Panel#listsusc'
};

const PeriodicRevisionLinks = {
  [Portal.Gascaribe]: 'https://gascaribe.com/revision-periodica/'
};

const OnlineMeterLinks = {
  [Portal.Efigas]: 'https://clientes.efigas.com.co/Consumo/ConsultarConsumo'
};

const PlaceHolderImageLink =
  'https://www.testingxperts.com/wp-content/uploads/2019/02/placeholder-img.jpg';

const ToDonationPDFLink =
  'https://dapre.presidencia.gov.co/normativa/normativa/DECRETO%20517%20DEL%204%20DE%20ABRIL%20DE%202020.pdf';

const RecaptchaLink = {
  Privacy: 'https://policies.google.com/privacy',
  Terms: 'https://policies.google.com/terms'
};

const DigitalInvoiceThanksVideoLinks = {
  [Portal.Gascaribe]:
    'https://gascaribe.com/gracias/?utm_source=portalweb&utm_medium=web-banner&utm_campaign=factura-digital-promo-1'
};

const CustomerPortalLinks = {
  [Portal.Efigas]: 'https://clientes.efigas.com.co/pqr/crear'
};

const ParticipaGanaCampaignLinks = {
  [Portal.Efigas]: 'https://participaygana.efigas.com.co/numerocupon'
};

const MainSiteUrlLink = MainSiteUrlLinks[portal];
const PrivacyPoliciesLink = PrivacyPoliciesLinks[portal];
const BrillaFinancingLink = BrillaFinancingLinks[portal];
const BrillaQuotaLink = BrillaQuotaLinks[portal];
const CookiesPoliciesLink = CookiesPoliciesLinks[portal];
const HabeasDataEmail = HabeasDataEmails[portal];
const WhatsAppHelpLink = WhatsAppHelpLinks[portal];
const PuntualizateLink = PuntualizateLinks[portal];
const GiveAwayPrivNotifLink = GiveAwayPrivNotifLinks[portal];
const InvoiceUrlLink = InvoiceUrlLinks[portal];
const PeriodicRevisionLink = PeriodicRevisionLinks[portal];
const GanaLocoCampaignLink = GanaLocoCampaignLinks[portal];
const DigitalInvoiceThanksVideoLink = DigitalInvoiceThanksVideoLinks[portal];
const ChatbotLink = ChatbotLinks[portal];
const SimulatorBrillaUrlLink = SimulatorBrillaUrlLinks[portal];
const CustomerPortalLink = CustomerPortalLinks[portal];
const OnlineMeterLink = OnlineMeterLinks[portal];
const ParticipaGanaCampaignLink = ParticipaGanaCampaignLinks[portal];
const PagaGanaCampaignTermsLink = PagaGanaCampaignTermsLinks[portal];

export {
  MainSiteUrlLink,
  PrivacyPoliciesLink,
  BrillaFinancingLink,
  BrillaQuotaLink,
  CookiesPoliciesLink,
  HabeasDataEmail,
  WhatsAppHelpLink,
  PuntualizateLink,
  GiveAwayPrivNotifLink,
  InvoiceUrlLink,
  PlaceHolderImageLink,
  ToDonationPDFLink,
  PeriodicRevisionLink,
  RecaptchaLink,
  GanaLocoCampaignLink,
  DigitalInvoiceThanksVideoLink,
  SimulatorBrillaUrlLink,
  ChatbotLink,
  CustomerPortalLink,
  OnlineMeterLink,
  ParticipaGanaCampaignLink,
  PagaGanaCampaignTermsLink
};
