import { Grid, Typography, makeStyles } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import TextInput from '../../../../Components/Inputs/TextInput';
import ModalProgress from '../../../../Components/Progress/Modal/ModalProgress';
import { Company } from '../../../../Configs/general';
import { AlertsDispatchContext } from '../../../../Contexts/AlertsContext';
import { ALERT_TYPE } from '../../../../Components/Alerts/alert_enums';

const PassKeyQuestionChallenge = props => {
  const { id, description, label, register, errors, loading, metadata } = props;

  const setAlert = useContext(AlertsDispatchContext);

  const classes = useStyles();

  useEffect(() => {
    if (metadata.hasPassKey) {
      return;
    }

    setAlert({
      type: ALERT_TYPE.ERROR,
      message: `${Company.contractConjugation.capitalized.singular.article} no cuenta con un código de activación.`
    });
  }, [metadata, setAlert]);

  return (
    <>
      {loading && <ModalProgress message="Estamos validando tus respuestas" />}
      <div key={id} className={classes.questionContainer}>
        <Grid container direction="row">
          <Grid item xs={12} sm={6} className={classes.questionText}>
            <Typography>{description}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              inputRef={register}
              name={id}
              autoFocus
              fullWidth
              disabled={loading || !metadata.hasPassKey}
              label={label}
              required={true}
              error={Boolean(errors[id])}
              helperText={errors[id] && errors[id].message}
              InputProps={{
                inputProps: {
                  type: 'text',
                  inputMode: 'numeric'
                }
              }}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  sectionText: {
    margin: theme.spacing(2, 0, 3, 0)
  },
  questionContainer: {
    marginBottom: theme.spacing()
  },
  questionText: {
    display: 'flex',
    alignItems: 'center'
  },
  month: {
    fontWeight: 500
  }
}));

export default PassKeyQuestionChallenge;
