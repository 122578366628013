import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback
} from 'react';
import { redirectOnAuthFailure } from '../Utils/Errors/Errors';
import { UserContext, UserDispatchContext } from './UserContext';
import { GetContractsAPI } from '../API/Contracts/ContractsAPI';
import * as Sentry from '@sentry/browser';
import _get from 'lodash/get';
import FullSizeProgress from '../Components/Progress/FullSize/FullSizeProgress';
import { logoutUser } from '../Utils/User/Actions';
import { CONTRACT_PRODUCT_TYPES } from '../Views/Contracts/contract_enums';

export const ContractsContext = createContext(null);
export const ContractsDispatchContext = createContext(null);
export const ConstructorContractsContext = createContext(null);
export const ContractsRefreshContext = createContext(null);
export const ContractSelectedContext = createContext(null);
export const ContractSetSelected = createContext(null);

const isUsingIE = window.navigator.userAgent.match(/(MSIE|Trident)/);

export const ContractsProvider = ({ children }) => {
  const [contracts, dispatch] = useState(null);
  const [constructorContracts, setConstructorContracts] = useState(null);
  const [selected, setSelected] = useState(null);
  const [refreshCounter, setRefresh] = useState(0);

  const currentUser = useContext(UserContext);
  const setCurrentUser = useContext(UserDispatchContext);
  const authToken = _get(currentUser, 'token');

  const usingIE = Boolean(isUsingIE);

  const refresh = useCallback(() => {
    dispatch(null);
    setConstructorContracts(null);
    setRefresh(refreshCounter + 1);
  }, [refreshCounter]);

  useEffect(() => {
    if (!authToken) {
      setSelected(null);
      dispatch(null);
      setConstructorContracts(null);
      return;
    }

    const fetchData = async () => {
      const response = await GetContractsAPI(authToken, usingIE);

      if (!response.success) {
        if (
          redirectOnAuthFailure(response, '', () => logoutUser(setCurrentUser))
        ) {
          return;
        }

        console.error(
          'Error loading contracts for user',
          response.error,
          response.error.response
        );
        return;
      }

      setSelected(GetSelectedContract(response.data.data));

      const retrievedContracts = response.data.data;

      setConstructorContracts(
        retrievedContracts.filter(
          contr => contr.productType === CONTRACT_PRODUCT_TYPES.Generic
        )
      );

      dispatch(
        retrievedContracts.filter(
          contr => contr.productType !== CONTRACT_PRODUCT_TYPES.Generic
        )
      );
    };

    fetchData();
  }, [authToken, refreshCounter, setCurrentUser, usingIE]);

  useEffect(() => {
    Sentry.configureScope(scope => {
      scope.setTag('selectedContract', selected ? selected.id : null);
    });
  }, [selected]);

  if (currentUser && !contracts) {
    return <FullSizeProgress />;
  }

  return (
    <ContractsContext.Provider value={contracts}>
      <ContractsDispatchContext.Provider value={dispatch}>
        <ConstructorContractsContext.Provider value={constructorContracts}>
          <ContractsRefreshContext.Provider value={refresh}>
            <ContractSelectedContext.Provider value={selected}>
              <ContractSetSelected.Provider value={setSelected}>
                {children}
              </ContractSetSelected.Provider>
            </ContractSelectedContext.Provider>
          </ContractsRefreshContext.Provider>
        </ConstructorContractsContext.Provider>
      </ContractsDispatchContext.Provider>
    </ContractsContext.Provider>
  );
};

export function GetSelectedContract(contracts) {
  if (!contracts || contracts.length === 0) {
    return null;
  }

  const firstContract = contracts[0];
  const selectedContract = contracts.find(contract => contract.selected);
  return selectedContract || firstContract;
}
