/* eslint-disable complexity */
import React, { useCallback, useLayoutEffect, useRef } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';

import TextInput from '../../../../Components/Inputs/TextInput';
import YesNoButton from '../../../../Components/Buttons/YesNoButton';
import clsx from 'clsx';

const ContractAssociationFormReferred = props => {
  const {
    label = '¿Tienes un código interno?',
    dialogMode,
    loading,
    referredCode,
    setReferredCode,
    isReferredCodeRequired,
    setIsReferredCodeRequired,
    errors
  } = props;

  // * OTHER HOOKS
  const classes = useStyles();

  const referredInputRef = useRef(null);
  const hasChangedInput = useRef(false);

  const referredCodeYesChecked = useCallback(() => {
    setIsReferredCodeRequired(true);
  }, [setIsReferredCodeRequired]);

  const referredCodeNoChecked = useCallback(() => {
    setReferredCode('');
    setIsReferredCodeRequired(false);
  }, [setReferredCode, setIsReferredCodeRequired]);

  useLayoutEffect(() => {
    if (isReferredCodeRequired && referredInputRef.current) {
      referredInputRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isReferredCodeRequired]);

  const errorText = hasChangedInput.current && errors.referredCode;

  return (
    <div className={classes.root}>
      <div className={classes.itemContainer}>
        <Typography className={classes.itemText}>{label}</Typography>
        <YesNoButton
          checked={isReferredCodeRequired}
          yesChecked={referredCodeYesChecked}
          noChecked={referredCodeNoChecked}
          color="primary"
          small="small"
        />
      </div>
      {isReferredCodeRequired && (
        <Grid
          container
          alignItems="baseline"
          className={classes.inputContainer}
          ref={referredInputRef}
        >
          <TextInput
            id={'referred_input'}
            className={clsx(classes.referredInput, {
              [classes.referredInputDialogMode]: dialogMode
            })}
            autoFocus
            value={referredCode}
            onChange={e => {
              setReferredCode(e.target.value);
              hasChangedInput.current = true;
            }}
            label={'Código'}
            error={Boolean(errorText)}
            helperText={errorText}
            disabled={loading}
          />
        </Grid>
      )}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      flexDirection: 'row'
    }
  },
  button: {
    fontSize: 14,
    fontWeight: 500,
    paddingBottom: theme.spacing(),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      paddingBottom: theme.spacing(2),
      paddingRight: 0,
      paddingLeft: 0
    }
  },
  referredIcon: {
    height: 14,
    width: 14,
    transform: 'scaleX(-1)',
    marginLeft: theme.spacing()
  },
  referredInput: {
    minWidth: 200,
    marginTop: 0,
    marginLeft: theme.spacing()
  },
  referredInputDialogMode: {
    minWidth: 250,
    marginTop: 0,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      minWidth: 150
    }
  },
  inputContainer: {
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'space-between'
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    }
  }
}));

export default ContractAssociationFormReferred;
