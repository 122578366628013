import React, {
  Fragment,
  useState,
  useContext,
  useCallback,
  useEffect
} from 'react';
import _capitalize from 'lodash/capitalize';
import _get from 'lodash/get';
import * as yup from 'yup';
import clsx from 'clsx';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, useMediaQuery } from '@material-ui/core';

import { PostAssociateContract } from '../../../API/Contracts/ContractsAPI';
import {
  UserContext,
  UserDispatchContext
} from '../../../Contexts/UserContext';
import { ContractsRefreshContext } from '../../../Contexts/ContractsContext';

import BaseButton from '../../../Components/Buttons/BaseButton';
import BaseDialog from '../../../Components/Dialogs/BaseDialog';
import { history } from '../../../Routes/history';

import {
  redirectOnAuthFailure,
  extractErrorMessage
} from '../../../Utils/Errors/Errors';
import { fullName } from '../../../Utils/Format/Names';
import { INVOICE_CONTRACTS, DEFAULT_ALIASES } from '../contract_enums';
import RequestDialog from '../../../Components/Dialogs/RequestDialog';
import ContractAssociationForm from '../ContractAssociation/ContractAssociationForm/ContractAssociationForm';
import RecaptchaDisclaimer from '../../../Components/Captcha/RecaptchaDisclaimer';
import { getRecaptchaToken } from '../../../Utils/Recaptcha';
import { RecaptchaAction } from '../../../Enums/recaptcha';
import { Company } from '../../../Configs/general';
import { DigitalInvoiceThanksVideoLink } from '../../../Configs/Links';
import { OpenNewTab } from '../../../Utils/Misc/Links';
import { logoutUser } from '../../../Utils/User/Actions';

const associateSchema = yup.object({
  aliasName: yup
    .string()
    .trim()
    .max(12, 'Debe tener 12 letras o menos.')
    .required('Ingresa un alias'),
  aliasIcon: yup.number().required('Selecciona un ícono'),
  referredCode: yup.string().when('$isReferredCodeRequired', {
    is: true,
    then: schema =>
      schema
        .trim()
        .matches(/^[a-zA-Z0-9]*$/, 'El campo debe ser alfanumérico')
        .max(50, 'Debe tener 50 caracteres o menos.')
        .required('Ingresa un código'),
    otherwise: schema => schema.trim().default('')
  })
});

const ContractAssociationDialogGDC = props => {
  // * CONTEXTS
  const currentUser = useContext(UserContext);
  const setCurrentUser = useContext(UserDispatchContext);
  const refreshContracts = useContext(ContractsRefreshContext);

  const { contract, open, handleClose, setAlert, dontRedirect = false } = props;
  const authToken = _get(currentUser, 'token');

  const allowedContractType =
    contract && INVOICE_CONTRACTS.includes(contract.type);

  // * STATE HOOKS
  const [aliasIcon, setAliasIcon] = useState(1);
  const [aliasName, setAliasName] = useState('');
  const [sendInvoice, setSendInvoice] = useState(true);
  const [digitalInvoice, setDigitalInvoice] = useState();
  const [digitalChannels, setDigitalChannels] = useState({
    email: true,
    whatsapp: false,
    sms: false
  });
  const [referredCode, setReferredCode] = useState('');
  const [isReferredCodeRequired, setIsReferredCodeRequired] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCaptcha, setLoadingCaptcha] = useState(true);
  const [canSubmit, setCanSubmit] = useState(false);
  const [errors, setErrors] = useState({});
  const [needsPhoneValidation, setNeedsPhoneValidation] = useState(false);
  const [openPhoneValidationDialog, setOpenPhoneValidationDialog] = useState(
    false
  );

  // * OTHER HOOKS
  const classes = useStyles();
  const theme = useTheme();
  const smallScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );

  // * FUNCTIONS
  const handleAssociate = useCallback(async () => {
    setLoading(true);
    setCanSubmit(false);

    const contractHasDigitalInvoice = contract.digitalInvoice;

    // If the contract has an active digital invoice,
    // the user's response is taken as sendInvoice.
    // If it does not have it active, sendInvoice will
    // be the same as digitalInvoice.

    const realSendInvoice = contractHasDigitalInvoice
      ? sendInvoice
      : digitalInvoice;

    const realDigitalInvoice = contractHasDigitalInvoice || digitalInvoice;

    const digitalInvoiceSettings = {
      digitalInvoice: realDigitalInvoice,
      sendInvoice: realSendInvoice,
      sendEmailInvoice: realSendInvoice,
      sendWhatsAppInvoice: realSendInvoice && digitalChannels.whatsapp,
      sendSmsInvoice: realSendInvoice && digitalChannels.sms
    };

    // When the contract to associate is not an
    // allowed one for digital invoice, it sets
    // every digital invoice setting to false
    if (!allowedContractType) {
      for (const key of Object.keys(digitalInvoiceSettings)) {
        digitalInvoiceSettings[key] = false;
      }
    }

    const newAssociation = {
      ...digitalInvoiceSettings,
      alias: aliasName.trim(),
      icon: aliasIcon,
      referredCode,
      origin: {
        url: window.location.pathname
      }
    };

    const recaptchaToken = await getRecaptchaToken(
      RecaptchaAction.AssociateContract
    );

    const response = await PostAssociateContract(
      authToken,
      contract.id,
      newAssociation,
      recaptchaToken
    );
    if (response.success) {
      if (digitalInvoice || realSendInvoice) {
        const isSafari = /^((?!chrome|android).)*safari/i.test(
          navigator.userAgent
        );
        if (isSafari) {
          window.location.href = DigitalInvoiceThanksVideoLink;
        } else {
          OpenNewTab(DigitalInvoiceThanksVideoLink);
        }
      }
      handleClose();
      setAlert({
        type: 'success',
        message: `Se ha asociado ${Company.contractConjugation.regular.singular.article} "${aliasName}"`
      });
      refreshContracts();
      if (!dontRedirect) {
        history.push('/');
      }
      return { unmounting: true };
    }
    if (
      redirectOnAuthFailure(response, '/', () => logoutUser(setCurrentUser))
    ) {
      return { unmounting: true };
    }
    setAlert({
      type: 'error',
      message: extractErrorMessage(response).message
    });
    setLoading(false);
    return { unmounting: false, closeDialog: true };
  }, [
    authToken,
    contract,
    aliasIcon,
    aliasName,
    refreshContracts,
    setAlert,
    handleClose,
    setCurrentUser,
    dontRedirect,
    digitalChannels.sms,
    digitalChannels.whatsapp,
    digitalInvoice,
    sendInvoice,
    referredCode,
    allowedContractType
  ]);

  const _renderAddress = dataInfo => {
    let text = dataInfo ? 'Sin dirección' : null;
    if (!text) {
      return <div className={classes.placeHolderBox} />;
    }
    if (dataInfo.city) {
      text = dataInfo.city;
    }
    if (dataInfo.city && dataInfo.department) {
      text = `${text}, `;
    }
    if (dataInfo.department) {
      text = text + dataInfo.department;
    }
    if (dataInfo.address) {
      text = `${text} ${dataInfo.address}`;
    }

    return (
      <Typography className={classes.labelText}>{_capitalize(text)}</Typography>
    );
  };

  const _renderName = text => {
    if (!text) {
      return <div className={classes.placeHolderBox} />;
    }
    return (
      <Typography className={classes.labelText}>{_capitalize(text)}</Typography>
    );
  };

  const onChangeAliasName = useCallback(value => {
    setAliasName(value);
    setErrors(errs => ({ ...errs, aliasName: null }));
  }, []);

  useEffect(() => {
    try {
      associateSchema.validateSync(
        { aliasName, aliasIcon, referredCode },
        { context: { isReferredCodeRequired } }
      );
      setErrors({});
      if (digitalInvoice) {
        setCanSubmit(Boolean(digitalInvoice));
      }
    } catch (err) {
      setCanSubmit(false);
      setErrors({
        [err.path]: err.errors[0]
      });
    }
  }, [
    aliasName,
    aliasIcon,
    referredCode,
    isReferredCodeRequired,
    digitalInvoice,
    setDigitalInvoice
  ]);

  useEffect(() => {
    if (contract) {
      if (INVOICE_CONTRACTS.includes(contract.type)) {
        setAliasIcon(contract.type);
        setAliasName(DEFAULT_ALIASES[contract.type]);
      } else {
        setAliasIcon(4);
        setAliasName('Especial');
      }
    }
  }, [contract]);

  const closePhoneDialog = useCallback(() => {
    setOpenPhoneValidationDialog(false);
  }, []);

  let actionText = 'Validar';
  if (currentUser && !currentUser.phone) {
    actionText = 'Agregar';
  }
  if (currentUser && currentUser.phoneValidated) {
    actionText = 'Confirmar';
  }

  const onSubmitAssociate = useCallback(() => {
    if (needsPhoneValidation) {
      setOpenPhoneValidationDialog(true);
      return;
    }

    handleAssociate();
  }, [needsPhoneValidation, handleAssociate]);

  const renderActions = () => {
    return (
      <Fragment>
        <BaseButton
          onClick={handleClose}
          variant="outlined"
          color="primary"
          size="small"
        >
          Cancelar
        </BaseButton>
        <BaseButton
          onClick={onSubmitAssociate}
          color="primary"
          disabled={!canSubmit || loading || loadingCaptcha}
          size="small"
          autoFocus
        >
          Asociar
        </BaseButton>
      </Fragment>
    );
  };

  const renderContent = () => {
    return (
      <div className={classes.content}>
        <div className={classes.contractDataContainer}>
          <Typography className={classes.subText}>
            Dirección del predio
          </Typography>
          {_renderAddress(contract)}
          <Typography className={classes.subText}>
            Nombre del titular
          </Typography>
          {_renderName(
            contract ? fullName(contract.holder, 'name', 'lastName') : null
          )}
        </div>

        <Typography className={classes.text}>
          Selecciona un icono y un alias para identificar{' '}
          {Company.contractConjugation.regular.singular.article} de manera
          sencilla
        </Typography>

        <ContractAssociationForm
          dialogMode
          currentUser={currentUser}
          contract={contract}
          sendInvoice={sendInvoice}
          setSendInvoice={setSendInvoice}
          isDigitalInvoice={digitalInvoice}
          setIsDigitalInvoice={setDigitalInvoice}
          digitalChannels={digitalChannels}
          setDigitalChannels={setDigitalChannels}
          aliasName={aliasName}
          setAliasName={onChangeAliasName}
          aliasIcon={aliasIcon}
          setAliasIcon={setAliasIcon}
          errors={errors}
          needsPhoneValidation={needsPhoneValidation}
          setNeedsPhoneValidation={setNeedsPhoneValidation}
          setCanSubmit={setCanSubmit}
          referredCode={referredCode}
          setReferredCode={code => setReferredCode(code.toUpperCase())}
          isReferredCodeRequired={isReferredCodeRequired}
          setIsReferredCodeRequired={setIsReferredCodeRequired}
          referredCodeEnable
          allowedContractType={allowedContractType}
        />

        <div
          className={clsx(classes.disclaimerContainer, {
            [classes.disclaimerContainerWithSweetAlert]: needsPhoneValidation
          })}
        >
          <RecaptchaDisclaimer
            loading={loadingCaptcha}
            setLoading={setLoadingCaptcha}
          />
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      {openPhoneValidationDialog && (
        <RequestDialog
          open={openPhoneValidationDialog}
          setDialog={setOpenPhoneValidationDialog}
          beforeClosing={closePhoneDialog}
          requestTitle={`${actionText} número celular`}
          requestCallback={handleAssociate}
          withoutObservation={true}
          backButton={{
            text: 'Cancelar',
            handle: closePhoneDialog
          }}
          submitButtonText={'Continuar'}
          requestNode={
            <Typography paragraph className={classes.text}>
              {`Para llevar a cabo esta acción es necesario ${actionText.toLowerCase()} tu número de celular`}
            </Typography>
          }
          contentSize="small"
        />
      )}
      <BaseDialog
        open={open}
        handleClose={handleClose}
        title={`Asociar ${Company.contractConjugation.regular.singular.main}`}
        actions={renderActions}
        content={renderContent}
        contentStyle={classes.dialogContent}
        fullScreen={smallScreen}
        paperClass={classes.dialogPaper}
      />
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  text: {
    fontSize: 14,
    alignSelf: 'flex-start',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 13,
      marginBottom: theme.spacing(2)
    }
  },
  content: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  dialogContent: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      padding: theme.spacing(3)
    }
  },
  contractDataContainer: {
    marginBottom: theme.spacing(1.5),
    width: '100%'
  },
  labelText: {
    alignSelf: 'flex-start',
    fontSize: 14,
    fontWeight: 500,
    textTransform: 'capitalize',
    marginBottom: theme.spacing(0.5),
    [theme.breakpoints.up('sm')]: {
      fontSize: 16
    },
    '&:last-of-type': {
      marginBottom: 0
    }
  },
  subText: {
    alignSelf: 'flex-start',
    display: 'inline',
    fontSize: 12,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 11
    }
  },
  aliasIcon: {
    minWidth: 67
  },
  dialogPaper: {
    maxWidth: 705
  },
  disclaimerContainer: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing()
  },
  disclaimerContainerWithSweetAlert: {
    marginTop: theme.spacing(2)
  }
}));

export default ContractAssociationDialogGDC;
