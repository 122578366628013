import { Grid, Typography, makeStyles } from '@material-ui/core';
import React, { useMemo } from 'react';
import QuestionMark from '../../../../Components/Adornments/QuestionMark';
import TextInput from '../../../../Components/Inputs/TextInput';
import ModalProgress from '../../../../Components/Progress/Modal/ModalProgress';
import { InvoiceHelpType } from '../../../../Enums/invoices';
import { joinNames } from '../../../../Utils/Format/Names';

const InvoiceQuestionChallenge = props => {
  const {
    id,
    description,
    label,
    register,
    errors,
    loading,
    setInvoiceHelp,
    metadata
  } = props;

  const classes = useStyles();

  const invoiceMonths = useMemo(() => {
    if (
      !metadata ||
      !metadata.detailInvoices ||
      metadata.detailInvoices.length === 0
    ) {
      return '';
    }

    const months = metadata.detailInvoices.map(
      detail => `${detail.monthLiteral} ${detail.year}`
    );

    return joinNames(months, ', ', ' o ');
  }, [metadata]);

  return (
    <>
      {loading && <ModalProgress message="Estamos validando tus respuestas" />}
      <div key={id} className={classes.questionContainer}>
        <Grid container direction="row">
          <Grid item xs={12} sm={6} className={classes.questionText}>
            <Typography>
              {description}{' '}
              {invoiceMonths && (
                <span className={classes.month}>{invoiceMonths}</span>
              )}
            </Typography>
            <ul></ul>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              inputRef={register}
              name={id}
              autoFocus
              fullWidth
              disabled={loading}
              label={label}
              required={true}
              error={Boolean(errors[id])}
              helperText={errors[id] && errors[id].message}
              InputProps={{
                endAdornment: (
                  <QuestionMark
                    onClick={() => setInvoiceHelp(InvoiceHelpType.Invoice)}
                  />
                ),
                inputProps: {
                  type: 'number',
                  inputMode: 'numeric'
                }
              }}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  sectionText: {
    margin: theme.spacing(2, 0, 3, 0)
  },
  questionContainer: {
    marginBottom: theme.spacing()
  },
  questionText: {
    display: 'flex',
    alignItems: 'center'
  },
  month: {
    fontWeight: 500
  }
}));

export default InvoiceQuestionChallenge;
