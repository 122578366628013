import React from 'react';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { SlideUpTransition } from '../../../Components/Transitions/Transitions';
import BaseDialog from '../../../Components/Dialogs/BaseDialog';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BaseButton from '../../../Components/Buttons/BaseButton';
import { ROUTE_NAMES } from '../../../Routes/Routes';
import { WrongContractDataModals } from './ContractAssociation';

function ContractAssociationNoCheckedModal(props) {
  const {
    open = false,
    controlWrongContractDataModal,
    setRedirectPage,
    isConstructorContract,
    isIndustrialContract
  } = props;
  const classes = useStyles();

  const handleSelectRoute = option => {
    setRedirectPage(option);
    controlWrongContractDataModal(WrongContractDataModals.SelectOptions, false);
    controlWrongContractDataModal(WrongContractDataModals.Confirm, true);
  };

  const handleOwnershipChange = () => {
    if (isIndustrialContract) {
      handleSelectRoute(`${ROUTE_NAMES.pqr}?type=cambio-titular-industrial`);
      return;
    }

    if (isConstructorContract) {
      handleSelectRoute(ROUTE_NAMES.pqr);
      return;
    }

    handleSelectRoute(ROUTE_NAMES.ownership);
  };

  const renderContent = () => {
    return (
      <Box className={classes.textContent}>
        <Typography style={{ fontSize: '14px' }}>
          ¿Cuál información es incorrecta?
        </Typography>

        <Box className={classes.buttonContent}>
          <BaseButton
            id="nameButton"
            onClick={handleOwnershipChange}
            disabled={false}
            color="primary"
            variant="outlined"
            fullWidth
          >
            Nombre del titular
          </BaseButton>

          <BaseButton
            id="addressButton"
            onClick={() => handleSelectRoute(ROUTE_NAMES.pqr)}
            disabled={false}
            color="primary"
            variant="outlined"
            fullWidth
          >
            Dirección del predio
          </BaseButton>
        </Box>
      </Box>
    );
  };

  const isMobileSize = isWidthDown('xs', props.width);
  return (
    <BaseDialog
      open={open}
      handleClose={() =>
        controlWrongContractDataModal(
          WrongContractDataModals.SelectOptions,
          false
        )
      }
      title="Datos incorrectos"
      content={renderContent}
      fullScreen={isMobileSize}
      contentStyle={classes.content}
      TransitionComponent={isMobileSize ? SlideUpTransition : undefined}
    />
  );
}

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'inherit',
    maxWidth: '100%',
    paddingTop: theme.spacing(2)
  },
  buttonContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '115px',
    margin: theme.spacing(2),
    justifyContent: 'space-between'
  }
}));

export default withWidth()(ContractAssociationNoCheckedModal);
