/* eslint-disable complexity */
import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';

import SimpleDivider from '../../../../../Components/Dividers/SimpleDivider';
import ConfirmActionDialog from '../../../../../Components/Dialogs/ConfirmActionDialog';

import ContractAssociationFormNotificationAuthorization from './ContractAssociationFormNotificationAuthorization';
import ContractAssociationFormNotificationChannels from './ContractAssociationFormNotificationChannels';
import ContractAssociationFormReferred from '../ContractAssociationFormReferred';

const ContractAssociationFormNotifications = props => {
  const {
    editMode,
    currentUser,
    contract,
    sendInvoice,
    setSendInvoice,
    isDigitalInvoice,
    setIsDigitalInvoice,
    digitalChannels,
    setDigitalChannels,
    setNeedsPhoneValidation,
    handleSweetAlertClick,
    digitalAlreadyActive,
    referredCodeEnable,
    showReferredInput,
    setReferredCode,
    referredCode,
    isReferredCodeRequired,
    setIsReferredCodeRequired,
    referredCodeLabel,
    showWhatsAppChannel,
    errors = {},
    setCanSubmit = null
  } = props;

  // * OTHER HOOKS
  const classes = useStyles();

  // * STATE HOOKS
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [checkedYesButton, setCheckedYesButton] = useState(false);
  const [checkedNoButton, setCheckedNoButton] = useState(false);

  // * GLOBAL VARS
  const openInvoiceChannels =
    (digitalAlreadyActive && sendInvoice) ||
    (!digitalAlreadyActive && isDigitalInvoice);

  const handleDigitalInvoiceChannels = useCallback(
    channel => {
      if (channel === 'reset') {
        setDigitalChannels(prev => ({
          ...prev,
          whatsapp: editMode ? contract.sendWhatsAppInvoice : false,
          sms: editMode ? contract.sendSmsInvoice : false
        }));
        return;
      }

      setDigitalChannels(prev => ({ ...prev, [channel]: !prev[channel] }));
    },
    [
      setDigitalChannels,
      editMode,
      contract.sendWhatsAppInvoice,
      contract.sendSmsInvoice
    ]
  );

  const handleDigitalInvoiceConfirmation = useCallback(
    isChecked => {
      setIsDigitalInvoice(isChecked);
      setCheckedYesButton(isChecked);
      setCheckedNoButton(!isChecked);
      if (!editMode && setCanSubmit) {
        setCanSubmit(true);
      }
    },
    [setIsDigitalInvoice, setCanSubmit, editMode]
  );

  const onDenyConfirmHandler = useCallback(() => {
    if (digitalAlreadyActive) {
      setSendInvoice(false);
    }

    if (!digitalAlreadyActive) {
      setIsDigitalInvoice(false);
    }
    handleDigitalInvoiceConfirmation(false);
    if (referredCodeEnable && setReferredCode) {
      setReferredCode('');
    }
    handleDigitalInvoiceChannels('reset');
  }, [
    digitalAlreadyActive,
    setSendInvoice,
    setIsDigitalInvoice,
    handleDigitalInvoiceChannels,
    handleDigitalInvoiceConfirmation,
    setReferredCode,
    referredCodeEnable
  ]);

  useEffect(() => {
    const realSendInvoice = digitalAlreadyActive
      ? sendInvoice
      : isDigitalInvoice;

    if (digitalAlreadyActive) {
      setCanSubmit(true);
    }

    const hasUserValidatedPhone = Boolean(
      currentUser.phone && currentUser.phoneValidated
    );
    const channelsNeedValidation =
      digitalChannels.whatsapp || digitalChannels.sms;
    const needsValidation =
      realSendInvoice && !hasUserValidatedPhone && channelsNeedValidation;

    if (needsValidation) {
      setNeedsPhoneValidation(true);
    } else {
      setNeedsPhoneValidation(false);
    }
  }, [
    currentUser,
    digitalChannels,
    setNeedsPhoneValidation,
    sendInvoice,
    isDigitalInvoice,
    digitalAlreadyActive,
    setCanSubmit
  ]);

  const openConfirmDialogHandler = useCallback(() => {
    const areNotificationsActive = digitalAlreadyActive && sendInvoice;
    const isDigitalInvoiceActive = !digitalAlreadyActive && isDigitalInvoice;

    if (areNotificationsActive || isDigitalInvoiceActive || !isDigitalInvoice) {
      setOpenConfirmDialog(true);
    }
  }, [digitalAlreadyActive, sendInvoice, isDigitalInvoice]);

  return (
    <div className={classes.root}>
      <ContractAssociationFormNotificationAuthorization
        editMode={editMode}
        sendInvoice={sendInvoice}
        setSendInvoice={setSendInvoice}
        isDigitalInvoice={isDigitalInvoice}
        openConfirmDialogHandler={openConfirmDialogHandler}
        handleSweetAlertClick={handleSweetAlertClick}
        digitalAlreadyActive={digitalAlreadyActive}
        handleDigitalInvoiceConfirmation={handleDigitalInvoiceConfirmation}
        checkedYesButton={checkedYesButton}
        checkedNoButton={checkedNoButton}
      />

      {openInvoiceChannels && (
        <>
          <SimpleDivider withoutMargin className={classes.itemDivider} />
          <ContractAssociationFormNotificationChannels
            digitalChannels={digitalChannels}
            handleDigitalInvoiceChannels={handleDigitalInvoiceChannels}
            showWhatsAppChannel={showWhatsAppChannel}
          />
        </>
      )}

      {showReferredInput && (
        <ContractAssociationFormReferred
          label={referredCodeLabel}
          referredCode={referredCode}
          setReferredCode={setReferredCode}
          isReferredCodeRequired={isReferredCodeRequired}
          setIsReferredCodeRequired={setIsReferredCodeRequired}
          errors={errors}
        />
      )}

      <ConfirmActionDialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        onAccept={() => handleDigitalInvoiceConfirmation(true)}
        onDeny={onDenyConfirmHandler}
        acceptText={`Activar ${
          digitalAlreadyActive ? 'notificaciones' : 'factura digital'
        }`}
      />
    </div>
  );
};

const useStyles = makeStyles(_theme => ({
  root: {
    width: '100%'
  },
  itemDivider: {
    marginTop: 0,
    marginBottom: 0
  }
}));

export default ContractAssociationFormNotifications;
