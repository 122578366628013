import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Card, FormControlLabel, Grid } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { UserContext } from '../../Contexts/UserContext';
import { AlertsDispatchContext } from '../../Contexts/AlertsContext';
import * as Yup from 'yup';
import clsx from 'clsx';
import BaseButton from '../../Components/Buttons/BaseButton';
import { SignupAPI } from '../../API/UserAPI';
import { extractErrorMessage } from '../../Utils/Errors/Errors';
import Recaptcha from '../../Components/Captcha/Recaptcha';
import ModalProgress from '../../Components/Progress/Modal/ModalProgress';
import { ROUTE_NAMES } from '../../Routes/Routes';
import { TERMS, TERMS_ROUTES } from '../Information/Information/Information';
import { isPrintableAscii } from '../../Utils/Misc/String';
import { config } from '../../Configs';
import { PrivacyPoliciesLink } from '../../Configs/Links';
import BaseTabs from '../../Components/Tabs/BaseTabs';
import NaturalPersonForm from './Forms/NaturalPersonForm';
import UserForm from './Forms/UserForm';
import ControlledCheckboxInput from '../../Components/Controlled/ControlledCheckbox';
import LegalPersonForm from './Forms/LegalPersonForm';
import { IDENTIFICATION_TYPE, USER_TYPE } from '../../Enums/users';

const signupSchema = Yup.object({
  businessName: Yup.mixed().when('$clientType', {
    is: USER_TYPE.LegalPerson,
    then: Yup.string()
      .trim()
      .required('Ingresa el nombre de la empresa'),
    otherwise: Yup.mixed().default(null)
  }),
  firstName: Yup.mixed().when('$clientType', {
    is: USER_TYPE.NaturalPerson,
    then: Yup.string()
      .trim()
      .max(60, 'Debe tener 60 letras o menos')
      .required('Ingresa tu nombre'),
    otherwise: Yup.mixed().default(null)
  }),
  lastName: Yup.mixed().when('$clientType', {
    is: USER_TYPE.NaturalPerson,
    then: Yup.string()
      .trim()
      .max(60, 'Debe tener 60 letras o menos')
      .required('Ingresa tus apellidos'),
    otherwise: Yup.mixed().default(null)
  }),
  businessIdentification: Yup.mixed().when('$clientType', {
    is: USER_TYPE.LegalPerson,
    then: Yup.number()
      .typeError('Ingresa un NIT válido')
      .positive('Ingresa un NIT válido')
      .required('Ingresa el NIT de tu empresa'),
    otherwise: Yup.mixed().default(null)
  }),
  identification: Yup.mixed().when('$clientType', {
    is: USER_TYPE.NaturalPerson,
    then: Yup.mixed().when('identificationType', {
      is: 'PP',
      then: Yup.string()
        .trim()
        .matches(/^[0-9a-zA-Z]*$/, 'Ingresa un número de pasaporte válido'),
      otherwise: Yup.number()
        .typeError('Ingresa un número de identificación válido')
        .positive('Ingresa un número de identificación válido')
        .nullable()
        .transform((value, originalValue) => {
          if (originalValue.trim() === '') {
            return null;
          }

          return value;
        })
    }),
    otherwise: Yup.mixed().default(null)
  }),
  identificationType: Yup.mixed().when('$clientType', {
    is: USER_TYPE.NaturalPerson,
    then: Yup.string().oneOf(
      ['CC', 'PP', 'CE', 'TI'],
      'Debes escoger una opción correcta'
    ),
    otherwise: Yup.mixed().default(null)
  }),
  checkDigit: Yup.mixed().when('$clientType', {
    is: USER_TYPE.LegalPerson,
    then: Yup.number()
      .typeError('Ingresa un dÍgito de verificación válido')
      .positive('Ingresa un dÍgito de verificación válido')
      .lessThan(10, 'Ingresa solo un dígito')
      .min(0, 'Ingresa un dígito de verificación válido')
      .required('Ingresa el dÍgito de verificación de tu NIT'),
    otherwise: Yup.mixed().default(null)
  }),
  email: Yup.string()
    .trim()
    .lowercase()
    .email('Debes ingresar un correo válido')
    .test(
      'is-ascii',
      'Este campo no puede contener tildes ni caracteres especiales',
      isPrintableAscii
    )
    .required('Ingresa un correo'),
  password: Yup.string()
    .trim()
    .required('Debes ingresar una contraseña')
    .min(8, 'Debe tener mínimo 8 caracteres'),
  confirmPassword: Yup.string()
    .trim()
    .oneOf([Yup.ref('password')], 'Las contraseñas deben coincidir'),
  terms: Yup.bool().oneOf([true], 'Debes aceptar los términos y condiciones')
});

const EMPTY_VALUES = {
  firstName: '',
  lastName: '',
  businessName: '',
  identification: '',
  identificationType: IDENTIFICATION_TYPE.CC,
  checkDigit: '',
  email: '',
  password: '',
  confirmPassword: '',
  terms: false
};

const FORM_TABS = [
  {
    label: 'Persona Natural',
    value: USER_TYPE.NaturalPerson
  },
  {
    label: 'Persona Jurídica',
    value: USER_TYPE.LegalPerson
  }
];

// eslint-disable-next-line complexity
const Signup = ({ history, width }) => {
  // * OTHER HOOKS
  const classes = useStyles();

  const recaptchaContainerRef = useRef(null);

  // * STATE HOOKS
  const currentUser = useContext(UserContext);
  const setAlert = useContext(AlertsDispatchContext);

  const [clientType, setClientType] = useState(USER_TYPE.NaturalPerson);
  const [loading, setLoading] = useState(false);
  const [captcha, setCaptcha] = useState(null);
  const [loadingCaptcha, setLoadingCaptcha] = useState(true);

  const recaptchaRef = useRef(null);

  const {
    handleSubmit,
    setError,
    register,
    control,
    errors,
    formState: { isValid: canSubmit }
  } = useForm({
    validationSchema: signupSchema,
    validationContext: { clientType },
    defaultValues: EMPTY_VALUES,
    mode: 'onChange'
  });

  // * FUNCTIONS
  useEffect(() => {
    if (currentUser) {
      history.replace('/');
    }
  }, [currentUser, history]);

  const onUserTypeChange = newValue => {
    setClientType(newValue);
  };

  const onCaptchaResolved = useCallback(token => {
    setCaptcha(token);
  }, []);

  const onCaptchaExpired = useCallback(() => {
    setCaptcha(null);
  }, []);

  const onCaptchaLoaded = useCallback(() => {
    setTimeout(() => {
      setLoadingCaptcha(false);
    }, 1000);
  }, []);

  const processSubmit = useCallback(
    async ({ email, password, ...rest }, event) => {
      event.preventDefault();
      setLoading(true);

      const clientData = {
        email,
        password
      };

      if (clientType === USER_TYPE.LegalPerson) {
        clientData.firstName = rest.businessName;
        clientData.identification = rest.businessIdentification;
        clientData.identificationType = IDENTIFICATION_TYPE.NIT;
        clientData.checkDigit = rest.checkDigit;
      } else {
        clientData.firstName = rest.firstName;
        clientData.lastName = rest.lastName;
        clientData.identification = rest.identification;
        clientData.identificationType = rest.identificationType;
      }

      const response = await SignupAPI({
        ...clientData,
        email,
        password,
        captcha
      });

      if (!response.success) {
        const error = extractErrorMessage(response);
        if (error.key === 'base') {
          setAlert({
            type: 'error',
            message: error.message
          });
        } else {
          if (
            clientType === USER_TYPE.LegalPerson &&
            error.key === 'firstName'
          ) {
            setError('businessName', 'api error', error.message);
          }
          setError(error.key, 'api error', error.message);
        }
        if (recaptchaRef.current) {
          recaptchaRef.current.reset();
          setCaptcha(null);
        }
        setLoading(false);
        return;
      }
      history.replace(ROUTE_NAMES.confirmEmail, { email });
    },
    [captcha, history, clientType, recaptchaRef, setAlert, setError]
  );

  const isMobileSize = isWidthDown('xs', width);

  const formTabsContents = [
    {
      value: USER_TYPE.NaturalPerson,
      element: NaturalPersonForm,
      props: {
        classes,
        isMobileSize,
        control,
        register,
        errors
      }
    },
    {
      value: USER_TYPE.LegalPerson,
      element: LegalPersonForm,
      props: {
        classes,
        register,
        errors
      }
    }
  ];

  return (
    <Fragment>
      {loadingCaptcha && <ModalProgress message={'Cargando'} />}
      <form
        id="Signup_form_container"
        onSubmit={handleSubmit(processSubmit)}
        className={clsx([classes.root])}
      >
        <div className={classes.cardContainer}>
          <Card
            className={clsx([
              classes.mainCard,
              { [classes.captchaLoading]: loadingCaptcha }
            ])}
          >
            <Typography variant="h2" className={classes.title}>
              Registro
            </Typography>
            <div className={classes.formContainer}>
              <Typography variant="subtitle2" className={classes.subtitle}>
                Ingresa tus datos personales
              </Typography>
              <BaseTabs
                tabs={FORM_TABS}
                contents={formTabsContents}
                onChange={onUserTypeChange}
              />
              <UserForm classes={classes} register={register} errors={errors} />
            </div>
            <Grid container direction="row" className={classes.termsContainer}>
              <Grid
                item
                xs={12}
                sm={6}
                className={classes.inputContainer}
                style={{ display: 'flex' }}
              >
                <FormControlLabel
                  control={
                    <ControlledCheckboxInput
                      id="Signup_input_terms"
                      name="terms"
                      control={control}
                      disabled={Boolean(currentUser)}
                      value="checkedI"
                    />
                  }
                  label={
                    <Typography className={classes.termsText}>
                      He leído y acepto los{' '}
                      <a
                        className={classes.link}
                        href={`${ROUTE_NAMES.information}/${
                          TERMS_ROUTES[TERMS.introduction]
                        }`}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        términos y condiciones
                      </a>{' '}
                      de uso, la{' '}
                      <a href={PrivacyPoliciesLink}>
                        política y aviso de privacidad
                      </a>{' '}
                      y autorizo el tratamiento de mis datos personales.
                    </Typography>
                  }
                />
              </Grid>
              <Grid
                ref={recaptchaContainerRef}
                item
                xs={12}
                sm={6}
                className={classes.inputContainer}
              >
                <Recaptcha
                  captchaRef={recaptchaRef}
                  referenceEl={recaptchaContainerRef}
                  heightScale={0.9}
                  locale={'es'}
                  sitekey={config.recaptcha.siteKey}
                  onResolved={onCaptchaResolved}
                  onExpired={onCaptchaExpired}
                  onLoaded={onCaptchaLoaded}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justify="center"
              className={classes.buttonContainer}
            >
              <Grid item sm={12} xs={12}>
                <BaseButton
                  id="Signup_button_submit"
                  loading={loading}
                  disabled={!canSubmit || !Boolean(captcha)}
                  type="submit"
                  color="primary"
                  variant="contained"
                  fullWidth
                >
                  Regístrate
                </BaseButton>
              </Grid>
            </Grid>
          </Card>
        </div>
      </form>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    backgroundColor: theme.palette.background.cardDark,
    overflowY: 'auto',
    overflowX: 'hidden',
    width: '100%',
    flexDirection: 'column',
    height: '100%'
  },
  link: {
    color: '#424242',
    fontWeight: 500
  },
  cardContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      minHeight: '100%'
    }
  },
  mainCard: {
    maxWidth: '800px',
    width: '800px',
    backgroundColor: theme.palette.common.white,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      margin: [[theme.spacing(4), theme.spacing(2)]],
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6)
    }
  },
  section: {
    width: '100%'
  },
  formSection: {
    width: '100%',
    marginTop: theme.spacing(2)
  },
  title: {
    margin: [[0, 0, theme.spacing(4), theme.spacing()]],
    [theme.breakpoints.down('lg')]: {
      margin: [[0, 0, theme.spacing(3), theme.spacing()]]
    }
  },
  subtitle: {
    margin: [[0, 0, theme.spacing(2), theme.spacing()]]
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  inputContainer: {
    padding: [[0, theme.spacing(), 0, theme.spacing()]]
  },
  input: {
    marginBottom: 31
  },
  inputError: {
    marginBottom: 14
  },
  spacer: {
    heigth: '100%',
    margin: [[0, theme.spacing(8), 0, theme.spacing(8)]],
    border: '1px solid',
    borderColor: theme.palette.common.black,
    opacity: 0.15,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  selector: {
    width: '30%'
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  footer: {
    backgroundColor: theme.palette.background.dark,
    justifyContent: 'flex-end',
    width: '100%',
    position: 'fixed',
    bottom: 0,
    zIndex: 10
  },
  captchaLoading: {
    visibility: 'hidden'
  },
  termsText: {
    fontSize: 11,
    [theme.breakpoints.up('sm')]: {
      fontSize: 12
    }
  },
  termsContainer: {
    padding: [[0, 0, 33, 0]],
    [theme.breakpoints.up('sm')]: {
      padding: [[0, 0, theme.spacing(3), 0]]
    }
  },
  buttonContainer: {
    paddingRight: theme.spacing(),
    paddingLeft: theme.spacing()
  },
  termsControl: {
    marginTop: theme.spacing(1)
  },
  termsError: {
    marginLeft: 36,
    marginTop: theme.spacing(0.5),
    fontSize: 12
  }
}));

export default withWidth()(Signup);
