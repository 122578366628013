import React, { Fragment, useCallback } from 'react';
import { Router } from 'react-router-dom';

import NotFoundIcon from '../Components/CustomIcons/NotFoundIcon';
import MaintenanceIcon from '../Components/CustomIcons/MaintenancePageIcon';
import AlertFullView from '../Components/Alerts/AlertFullView';
import ErrorBoundary from '../Components/ErrorBoundary/ErrorBoundary';

import { history } from './history';
import { portal, Portal } from '../Configs/general';
import RoutesGDC from './Routes.GDC';
import RoutesGDG from './Routes.GDG';
import RoutesEFG from './Routes.EFG';
import RoutesAAA from './Routes.AAA';

// eslint-disable-next-line no-warning-comments
// TODO: Decide with the team the route names in spanish. These should be telling, short & with no special characters
export const ROUTE_NAMES = {
  home: '/',
  payments: '/payments',
  transaction: '/payments/transaction',
  payContract: '/payments/contract',
  payCoupon: '/payments/coupon',
  information: '/information',
  register: '/register',
  login: '/login',
  forgot: '/forgot',
  recover: '/recover',
  verify: '/verify',
  brilla: '/brilla',
  contracts: '/contracts',
  associate: '/associate',
  invoices: '/invoices',
  map: '/closer',
  confirmEmail: '/confirm-email',
  forgotPasswordEmail: '/password-email',
  profile: '/profile',
  changeEmail: '/change-email',
  pqr: '/pqr',
  donations: '/donations',
  revision: '/periodic-revision',
  debtStatus: '/contracts/debt',
  ownership: '/ownership',
  requests: '/solicitudes',
  faq: '/faq',
  currentDebtSummary: '/contracts/debt/deuda-actual',
  deferredDebtSummary: '/contracts/debt/deuda-financiada',
  refinancingDeferred: '/contracts/debt/deuda-financiada/refinanciacion',
  refinancingCurrent: '/contracts/debt/deuda-actual/refinanciacion',
  deferredPayment: '/contracts/debt/deuda-financiada/pagar',
  campaigns: '/campaigns',
  transactionRegister: '/completar-registro',
  transactions: '/transacciones',
  verifyVisit: '/orders',
  insurancesHome: '/seguros',
  practiseguroDetails: '/seguros/practiseguro',
  acquireInsurance: '/seguros/adquirir',
  managePractiseguro: '/seguros/gestionar/practiseguro',
  inProcessInsurancePage: '/seguros/en-proceso',
  subrogationRequest: '/solicitud-subrogacion',
  digitalInvoice: '/factura-digital',
  updateUser: '/update-user'
};

export const ROUTE_NAME_ALIAS = {
  [ROUTE_NAMES.pqr]: 'PQR',
  [ROUTE_NAMES.ownership]: 'Cambio de titular',
  [`${ROUTE_NAMES.pqr}?type=cambio-titular-industrial`]: 'PQR - Cambio de razón social'
};

const withBoundary = Component => {
  const hydratedComponent = props => (
    <ErrorBoundary
      errorComponent={
        <AlertFullView
          icon={MaintenanceIcon}
          title={'Ha ocurrido un error, por favor, actualiza la página.'}
        />
      }
    >
      <Component {...props} />
    </ErrorBoundary>
  );

  return hydratedComponent;
};

const Routes = () => {
  const NotFoundView = useCallback(() => {
    return (
      <AlertFullView
        icon={NotFoundIcon}
        title={
          <Fragment>
            <strong>¡Ups!</strong> parece que la página que buscas{' '}
            <strong>no está disponible</strong>
          </Fragment>
        }
        redirectText={'Regresar al inicio'}
        redirectTo={ROUTE_NAMES.home}
      />
    );
  }, []);

  const renderRoutes = () => {
    switch (portal) {
      case Portal.Gascaribe:
        return (
          <RoutesGDC withBoundary={withBoundary} NotFoundView={NotFoundView} />
        );
      case Portal.Gasguajira:
        return (
          <RoutesGDG withBoundary={withBoundary} NotFoundView={NotFoundView} />
        );
      case Portal.Efigas:
        return (
          <RoutesEFG withBoundary={withBoundary} NotFoundView={NotFoundView} />
        );
      case Portal.TripleA:
        return (
          <RoutesAAA withBoundary={withBoundary} NotFoundView={NotFoundView} />
        );
      default:
        return <></>;
    }
  };

  return <Router history={history}>{renderRoutes()}</Router>;
};

export default React.memo(Routes);
